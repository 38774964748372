import React from "react";
import { Box, Button, CardActionArea, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import Footer from "./Footer";
import info from "../assets/info.png";

export default function Impressum() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      {" "}
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0.25, 0.80)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      >
        <Card style={{ padding: "20px", margin: "10px" }}>
          <img src={info} alt="Info" style={{ maxWidth: "90%" }}></img>
        </Card>
      </Backdrop>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            color: "black",
            boxShadow: "rgba(0, 0, 0, 0.0) 0px 2px 8px",
            textTransform: "none",
            flexGrow: "1",
            borderRadius: "0.3rem",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <CardActionArea
            onClick={() => {
              handleToggle();
            }}
          >
            <Typography style={{ fontSize: "0.5rem", padding: "10px" }}>
              Impressum / Legal Notice
            </Typography>
          </CardActionArea>
        </Card>
      </Box>
    </div>
  );
}
