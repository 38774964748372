import React, { useState, useEffect, useRef } from "react";
import Nav from "./Nav";
import Avatar from "@mui/material/Avatar";
import { Button, Card } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import Footer from "./Footer";
import { db } from "../firebase";
import CameraIcon from "@mui/icons-material/Camera";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import yt_black from "../assets/yt_black.png";
import insta_black from "../assets/insta_black.png";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import RecommendIcon from "@mui/icons-material/Recommend";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import CodeIcon from "@mui/icons-material/Code";
import ExamplePhotos from "./ExamplePhotos";

export default function About() {
  const [allPhotos, setAllPhotos] = useState([]);
  const [loaded, setLoaded] = useState(false);

  let buttonStyle = {
    padding: "0px",
    color: "black",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
    textTransform: "none",
    flexGrow: "1",
    borderRadius: "0.3rem",
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  };

  let links = {
    mobile: {
      instagram: "instagram://user?username=ryan.steimer",
      youTube:
        "intent://@ryan-steimer/#Intent;scheme=vnd.youtube;package=com.google.android.youtube;S.browser_fallback_url=market://details?id=com.google.android.youtube;end;",
    },

    web: {
      instagram: "https://www.instagram.com/ryan.steimer/",
      youTube: "https://www.youtube.com/@ryan-steimer",
    },
  };

  useEffect(() => {
    const fbListener = subscribeToAllPhotos();

    return () => {
      fbListener();
    };
  }, []);

  /* Randomize array in-place using Durstenfeld shuffle algorithm */
  function shuffleArray(array) {
    let helper = array;
    for (var i = helper.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = helper[i];
      helper[i] = helper[j];
      helper[j] = temp;
    }

    return helper;
  }

  function subscribeToAllPhotos() {
    const firebaseListener = db
      .collection("allPhotos")
      .doc("photos")
      .onSnapshot((doc) => {
        let fireBaseData = doc.data();
        let shuffled = shuffleArray(fireBaseData.photoArray);
        setAllPhotos(shuffled);
        setLoaded(true);
      });
    return firebaseListener;
  }
  return (
    <div style={{ maxWidth: "940px", margin: "0 auto", paddingTop: "20px" }}>
      <Nav />
      <Box
        style={{
          borderRadius: "0.3rem",
          margin: "15px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "10px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
          paddingBottom: "25px",
          padding: "10px",
        }}
      >
        <Box>
          <Box style={{ flexGrow: 1 }}>
            <Avatar
              alt="Ryan Steimer"
              src={
                "https://firebasestorage.googleapis.com/v0/b/photography-website-a5dd5.appspot.com/o/Photography%2FAbout%2FAbout_Ryan_2-4.jpg?alt=media&token=ebd404db-f967-4fc0-974f-516f9a6666ee"
              }
              sx={{
                margin: "5px",
                transition: "transform .5s ease",
                width: "19rem",
                maxWidth: "85vw",
                height: "15rem",
                ":hover": {
                  transform: "scale(1.02)", // theme.shadows[20]
                },
              }}
              variant="rounded"
            />
          </Box>
        </Box>
        <Box style={{ maxWidth: "560px", padding: "2px" }}>
          <Typography style={{ wordWrap: "break-all", fontSize: "0.9rem" }}>
            Welcome to my webpage. My name is Ryan and over the last couple of
            years I have started to collect videos and photos of my travels and
            encounters with wildlife. Currently I am based in Munich, doing my
            Master's Degree in Computer Science and working as an IT-Consultant.
            When I am not studying, working or watching the latest Star Wars
            franchise, I try to spend as much time I can, kayaking, hiking,
            diving or finding new spots to observe the local wildlife. I hope
            you enjoy my work. Feel free to chuck me a message for some
            feedback, tips, ideas or just to say hello. Currently I use a
            Panaosnic GH5 & GH6 for most of my photos and videos.
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          maxWidth: "900px",
          paddingLeft: "15px",
          paddingRight: "15px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "stretch",
          gap: "10px",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <Button sx={buttonStyle}>
          <Link to="/Photography">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <CameraIcon></CameraIcon>
              <Typography style={{ marginLeft: "10px" }}>
                Check out my photography
              </Typography>
            </Box>
          </Link>
        </Button>
        <Button sx={buttonStyle}>
          <Link to="/Videography">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <VideoCameraBackIcon />
              <Typography style={{ marginLeft: "10px" }}>
                Check out my videography
              </Typography>
            </Box>
          </Link>
        </Button>
        <Button sx={buttonStyle}>
          <a
            href={isMobile ? links.mobile.youTube : links.web.youTube}
            target="_blank"
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
                padding: "20px",
              }}
            >
              <img
                src={yt_black}
                style={{
                  height: "1.1rem",
                  paddingTop: "0px",
                }}
                alt="YouTube-Link"
              ></img>
              <Typography style={{ marginLeft: "10px" }}>
                Subscribe on YouTube{" "}
              </Typography>
            </Box>
          </a>
        </Button>
        <Button sx={buttonStyle}>
          <a
            href={isMobile ? links.mobile.instagram : links.web.instagram}
            target="_blank"
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <img
                src={insta_black}
                style={{
                  height: "1.5rem",
                  paddingTop: "0px",
                }}
                alt="Insta-Link"
              ></img>
              <Typography style={{ marginLeft: "10px" }}>
                Follow me on Instagram{" "}
              </Typography>
            </Box>
          </a>
        </Button>
        {/*         <Button sx={buttonStyle}>
          <Link to="/Equipment">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <HomeRepairServiceIcon></HomeRepairServiceIcon>
              <Typography style={{ marginLeft: "10px" }}>
                Equipment i use...
              </Typography>
            </Box>
          </Link>
        </Button> */}
        <Button sx={buttonStyle}>
          <a href="mailto:ryan@steimer.tech.de?subject=Contact, Ryan Steimer, Website&body=Hey Ryan, ">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <SendIcon
                sx={{
                  "&:hover": {},
                }}
              ></SendIcon>
              <Typography style={{ marginLeft: "10px" }}>
                Chuck me a message{" "}
              </Typography>
            </Box>
          </a>
        </Button>
        {/*         <Button sx={buttonStyle}>
          <Link to="/ITandDigital">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <CodeIcon></CodeIcon>
              <Typography style={{ marginLeft: "10px" }}>
                Professional Services
              </Typography>
            </Box>
          </Link>
        </Button> */}
      </Box>
      <Footer></Footer>
    </div>
  );
}
