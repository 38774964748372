import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Nav.css";
import ScrollButton from "./ScrollToTop";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import CottageIcon from "@mui/icons-material/Cottage";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
export default function Navbar() {
  useEffect(() => {
    document.querySelectorAll(".nav-li").forEach((link) => {
      console.log(link.parentElement.href);
      if (link.parentElement.href === window.location.href) {
        link.setAttribute("aria-current", "page");
      }
    });
    return () => {};
  }, []);

  return (
    <div
      style={{
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 20px -20px",
        marginBottom: "35px",
      }}
    >
      <div className="container">
        <ul className="nav-ul">
          <Box style={{ flexGrow: "1" }}>
            <a href="/" className="nav-a">
              <li className="nav-li">
                {window.innerWidth > 600 ? (
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    gap="10px"
                    alignItems="center"
                  >
                    <CottageIcon></CottageIcon>
                    <Typography>Home</Typography>
                  </Box>
                ) : (
                  <CottageIcon></CottageIcon>
                )}{" "}
              </li>
            </a>
          </Box>

          <Box style={{ flexGrow: "1" }}>
            <a href="/Photography" className="nav-a">
              <li className="nav-li">
                {window.innerWidth > 600 ? (
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    gap="10px"
                    alignItems="center"
                  >
                    <PhotoCameraIcon />
                    <Typography>Photography</Typography>
                  </Box>
                ) : (
                  <PhotoCameraIcon />
                )}
              </li>
            </a>
          </Box>
          <Box style={{ flexGrow: "1" }}>
            <a href="/Videography" className="nav-a">
              <li className="nav-li">
                {window.innerWidth > 600 ? (
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    gap="10px"
                    alignItems="center"
                  >
                    <VideoCameraBackIcon />
                    <Typography>Videography</Typography>
                  </Box>
                ) : (
                  <VideoCameraBackIcon />
                )}{" "}
              </li>
            </a>
          </Box>
        </ul>
      </div>
      <ScrollButton></ScrollButton>
    </div>
  );
}
